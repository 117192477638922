<template>
  <v-card class="mb-16" height="182px" width="182px" v-if="subCategory && screenType === 'desktop'"
          style="cursor:pointer;">
    <div class="subCategory-card-bubble1">
    </div>
    <div class="subCategory-card-bubble2">
    </div>
    <p>
      <span class="font-size-16" v-if="category.title.length>40">{{ category.title.substring(0, 40) }}</span>
      <span class="font-size-16" v-else>{{ category.title }}</span>
    </p>
    <div v-if="category.description">
      <p>
      <span class="font-size-16" v-if="category.description.length>40">{{
          category.description.substring(0, 40)
        }}</span>
        <span class="font-size-16" v-else>{{ category.description }}</span>
      </p>
    </div>
    <div style="font-size: 14px!important" v-if="!hideDurationStatus">
      {{ category.duration_text }}
    </div>
    <!--    <br>-->
    <!--    <v-img class="mb-1" style="display: table;margin-right:auto;margin-left:auto;"  height="45" width="45" :src="path+category.path"></v-img>-->
  </v-card>
  <v-card v-else-if="!subCategory && screenType === 'desktop'" height="9vh" style="cursor:pointer;padding:0!important">
    <v-row style="padding:0!important">
      <v-col cols="3">
        <img class="ml-3" :width="windowWidth > '1400'  ?   '50' : '30'" :src="path+category.path" alt="icon">
      </v-col>
      <v-col cols="9" class="text-left pt-2">
        <span style="font-size: 0.9vw" v-if="category.title.length>30">{{ category.title.substring(0, 30) }}</span>
        <span style="font-size: 0.9vw" v-else>{{ category.title }}</span> <br class="pa-0">
        <span style="font-size: 0.7vw" v-if="!hideDurationStatus">{{ category.duration_text }}</span>
      </v-col>
    </v-row>
    <!--    <div>-->
    <!--      <span style="position:absolute;margin-top: -4.5vh;margin-left: -4vw!important"-->
    <!--            v-if="category.title.length>30">{{ category.title.substring(0, 30) }}</span>-->
    <!--      <span style="position:absolute;margin-top: -4.5vh;margin-left: -4vw!important" v-else>{{ category.title }}</span> <br>-->
    <!--      <span-->
    <!--          style="position:absolute;margin-top:-4.5vh;margin-left:-4vw;font-size: 0.8vw!important">{{-->
    <!--          category.duration_text-->
    <!--        }}</span>-->
    <!--    </div>-->
  </v-card>

  <v-card class="mb-1 mt-1" height="100" width="100%" v-else-if="subCategory && screenType !== 'desktop'"
          style="cursor:pointer;">
    <div class="subCategory-card-bubble1">
    </div>
    <div class="subCategory-card-bubble2">
    </div>
    <p>
      <span class="font-size-16" v-if="category.title.length>40">{{ category.title.substring(0, 40) }}</span>
      <span class="font-size-16" v-else>{{ category.title }}</span>
    </p>
    <div v-if="category.description">
      <p>
      <span class="font-size-16" v-if="category.description.length>40">{{
          category.description.substring(0, 40)
        }}</span>
        <span class="font-size-16" v-else>{{ category.description }}</span>
      </p>
    </div>
    <div style="font-size: 14px!important" v-if="!hideDurationStatus">
      {{ category.duration_text }}
    </div>
    <!--    <br>-->
    <!--    <v-img class="mb-1" style="display: table;margin-right:auto;margin-left:auto;"  height="45" width="45" :src="path+category.path"></v-img>-->
  </v-card>
  <v-card v-else-if="!subCategory && screenType !== 'desktop'" height="9vh" style="cursor:pointer;padding:0!important">
    <v-row style="padding:0!important">
      <v-col cols="3">
        <img class="ml-3" width="30" :src="path+category.path" alt="icon">
      </v-col>
      <v-col cols="9" class="text-left pt-2">
        <span style="font-size: 3.9vw" v-if="category.title.length>30">{{ category.title.substring(0, 30) }}</span>
        <span style="font-size: 3.9vw" v-else>{{ category.title }}</span> <br class="pa-0">
        <span style="font-size: 2.7vw" v-if="!hideDurationStatus">{{ category.duration_text }}</span>
      </v-col>
    </v-row>
    <!--    <div>-->
    <!--      <span style="position:absolute;margin-top: -4.5vh;margin-left: -4vw!important"-->
    <!--            v-if="category.title.length>30">{{ category.title.substring(0, 30) }}</span>-->
    <!--      <span style="position:absolute;margin-top: -4.5vh;margin-left: -4vw!important" v-else>{{ category.title }}</span> <br>-->
    <!--      <span-->
    <!--          style="position:absolute;margin-top:-4.5vh;margin-left:-4vw;font-size: 0.8vw!important">{{-->
    <!--          category.duration_text-->
    <!--        }}</span>-->
    <!--    </div>-->
  </v-card>

</template>

<script>
export default {
  name: "Category",
  props: ['category', 'subCategory'],
  data: () => {
    return {
      screenType: null,
      hideDurationStatus: null,
      path: process.env.VUE_APP_BASE_URL,
      windowWidth: 1000
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
  },
  mounted() {
    this.getScreenType()
    this.hideDurationStatus = this.$route.path.indexOf("book") >= 1;
    this.windowWidth = window.innerWidth
  },
}
</script>

<style scoped>
.font-size-16 {
  color: white;
  font-size: 16px !important;
}

.subCategory-card-bubble1 {
  bottom: 0px !important;
  left: 30px !important;
  height: 60px !important;
  width: 120px !important;
  opacity: 0.2 !important;
  background-color: #ffffff;
  border-top-left-radius: 70px !important;
  border-top-right-radius: 70px !important;
  display: inline-block !important;
  z-index: 1 !important;
  position: absolute !important;
  overflow: hidden !important;
  /*transform: translateX(-100px) !important;*/
}

.subCategory-card-bubble2 {
  bottom: -181px !important;
  left: -5px !important;
  height: 108px !important;
  width: 101px !important;
  opacity: 0.2 !important;
  background-color: white;
  border-top-right-radius: 190px !important;
  display: inline-block !important;
  z-index: 1 !important;
  position: absolute !important;
  transform: translateY(-180px) !important;
}
</style>