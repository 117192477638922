<template>
  <div>
    <div v-if="screenType === 'desktop'" class="margin-container-left">
      <v-row>
        <v-col cols="12" class="text-left">
          <v-btn class="breadcrumb text-capitalize" text to="/my-page">My Page</v-btn>
          <v-icon>mdi-chevron-right</v-icon>
          <v-btn class="text-capitalize" :class="selectedCategoryTitle === null  ? 'breadcrumb-current' : 'breadcrumb' "
                 text
                 @click="changeBreadcrumb('library')"
                 style="font-size: 14px!important">
            {{ library }}
          </v-btn>
          <v-icon v-if="selectedCategoryTitle">mdi-chevron-right</v-icon>
          <v-btn v-if="selectedCategoryTitle" class="text-capitalize"
                 :class="selectedSubCategoryTitle === null  ? 'breadcrumb-current' : 'breadcrumb' "
                 text
                 @click="changeBreadcrumb('main')"
          >
            {{ selectedCategoryTitle }}
          </v-btn>
          <v-icon v-if="selectedSubCategoryTitle">mdi-chevron-right</v-icon>
          <v-btn v-if="selectedSubCategoryTitle" class="text-capitalize"
                 :class="selectedSubCategoryTitle !== null  ? 'breadcrumb-current' : 'breadcrumb' " text>
            {{ selectedSubCategoryTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-7">
        <v-col cols="12" class="text-left custom-margin-left-main">
          <v-row>
            <div class="custom-line">&nbsp;</div>
            <v-col class="page-title text-left">{{ currentPage }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" class="text-left">
          <v-btn style="font-size: 13px!important;" class="breadcrumb-mobile text-capitalize" text to="/my-page">My Page</v-btn>
          <v-icon small style="margin-left: -16px">mdi-chevron-right</v-icon>
          <v-btn class="text-capitalize"  :class="selectedCategoryTitle === null  ? 'breadcrumb-current-mobile' : 'breadcrumb-mobile' "
                 text
                 @click="changeBreadcrumb('library')"
                 style="font-size: 13px!important;margin-left: -15px">
            {{ library }}
          </v-btn>
          <v-icon small style="margin-left: -16px" v-if="selectedCategoryTitle">mdi-chevron-right</v-icon>
          <v-btn v-if="selectedCategoryTitle" class="text-capitalize"
                 :class="selectedSubCategoryTitle === null  ? 'breadcrumb-current-mobile' : 'breadcrumb-mobile' "
                 style="font-size: 13px!important;margin-left: -15px"
                 text
                 @click="changeBreadcrumb('main')"
          >
            {{ selectedCategoryTitle }}
          </v-btn>
          <v-icon small style="margin-left: -16px" v-if="selectedSubCategoryTitle">mdi-chevron-right</v-icon>
          <v-btn v-if="selectedSubCategoryTitle" class="text-capitalize"
                 style="font-size: 13px!important;margin-left: -15px"
                 :class="selectedSubCategoryTitle !== null  ? 'breadcrumb-current-mobile' : 'breadcrumb-mobile' " text>
            {{ selectedSubCategoryTitle }}
          </v-btn>
        </v-col>
      </v-row>
<!--      <v-row class="my-7">-->
<!--        <v-col cols="12" class="text-left custom-margin-left-main">-->
<!--          <v-row>-->
<!--            <div class="custom-line-mobile">&nbsp;</div>-->
<!--            <v-col class="page-title text-left">{{ currentPage }}</v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: ['library', 'currentPage', 'selectedSubCategoryTitle', 'selectedCategoryTitle'],
  data() {
    return {
      screenType: null,
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    changeBreadcrumb(type) {
      if (type === 'library') {
        this.$emit('changeBreadCrumbLibrary')
      } else {
        this.$emit('changeBreadCrumbMain')
      }
    }
  },
  mounted() {
    this.getScreenType()
  }
}
</script>

<style scoped>
.page-title {
  font-family: Blinker-Bold, serif;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 1;
  line-height: normal;
  letter-spacing: normal;
  color: #ffbe66;
}

.breadcrumb {
  font-size: 14px;
  color: #848e99;
}


.breadcrumb-mobile{
  font-size: 8px;
  color: #848e99;
}

.breadcrumb-current {
  font-size: 14px;
  color: #39bf88;
}

.breadcrumb-current-mobile {
  font-size: 8px;
  color: #39bf88;
}

.custom-line {
  font-size: 20px !important;
  margin-top: 10px !important;
  border-color: #ffbe66;
  border-left: 1px !important;
  margin-right: 20px !important;
  height: 48px !important;
  background: #ffbe66;
  transform: rotateZ(40deg) !important;
  max-width: 50px !important;
}

.custom-margin-left-main {
  margin-left: 2vw !important;
}

.margin-container-left {
  margin-left: 4vw !important;
}
</style>