<template>
  <v-card
      class="mx-auto"
      color="#f2f3f7"
      elevation="0"
      style="border-radius: 15px!important"
  >
    <v-img
        @click="$router.push('/' + product.type + '/' + product.slug)"
        style="cursor: pointer;border-radius:15px!important"
        height="200px"
        :src="baseURL + product.image"></v-img>

    <v-card-title class="text-custom-title">
      {{ product.title }}
    </v-card-title>
    <div class="text-custom-time text-left">
      {{ product.duration_text }}
    </div>
<!--    <v-card-subtitle>-->
<!--      {{ product.type }}-->
<!--    </v-card-subtitle>-->

<!--    <v-card-actions>-->
<!--      <v-btn-->
<!--          height="60px"-->
<!--          color="orange lighten-2"-->
<!--          text-->
<!--          :to="'/' + product.type + '/' + product.id"-->
<!--      >-->
<!--        More-->
<!--      </v-btn>-->
<!--      <v-btn-->
<!--          height="60px"-->
<!--          v-if="product.type == 'book'"-->
<!--          color="primary lighten-2"-->
<!--          text-->
<!--          :to="'/order/'+product.id"-->
<!--      >-->
<!--        Buy-->
<!--      </v-btn>-->

<!--      <v-spacer></v-spacer>-->

<!--      <v-btn-->
<!--          icon-->
<!--          @click.stop.prevent="show == product.id ? show = null : show = product.id"-->
<!--      >-->
<!--        <v-icon>{{ show == product.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
<!--      </v-btn>-->
<!--      <div v-if="this.isAuthenticated">-->
<!--        <v-btn text class="text&#45;&#45;secondary" @click="toggleBookmark(product)" v-if="product.bookmarked">-->
<!--          <v-icon>mdi-bookmark</v-icon>-->
<!--        </v-btn>-->
<!--        <v-btn text class="text&#45;&#45;secondary" @click="toggleBookmark(product)" v-else>-->
<!--          <v-icon>mdi-bookmark-outline</v-icon>-->
<!--        </v-btn>-->
<!--      </div>-->
<!--    </v-card-actions>-->

    <v-expand-transition>
      <div v-show="show === product.id">
        <v-divider></v-divider>

        <v-card-text>
          {{ product.content }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

export default {
  name: "ProductCard",
  props: ['product'],
  data() {
    return {
      show: null,
    }
  }
}
</script>

<style scoped>
.text-custom-title{
  color: #6d6d6d!important;
  font-size: 16px!important;
}
.text-custom-time{
  color: #a8a8a8!important;
  font-size: 14px!important;
  margin-left: 0.8vw!important;
  margin-top: -16px!important;
  padding-bottom: 15px!important;
}
</style>